<template>
    <div class="content">
        <main class="privacy">
            <section class="header">
                <h2 class="title">
                    {{ $t("general.privacy") }}
                </h2>
            </section>
            <section class="privacy-inner">
                <div class="privacy-element">
                    <p>
                        {{ $t("general.privacy-intro") }}
                    </p>
                </div>

                <div class="privacy-element">
                    <h3>{{ $t("general.privacy-contact") }}</h3>
                    <p>
                        {{ company }}<br />
                        {{ vat }}<br />
                        <a href="https://rendersbyjulie.be"
                            >https://rendersbyjulie.be</a
                        ><br />
                        {{ emailDecoded }}
                    </p>
                </div>

                <div class="privacy-element">
                    <h3>{{ $t("general.privacy-processing") }}</h3>
                    <p v-html="$t('general.privacy-processing-text')"></p>
                </div>

                <div class="privacy-element">
                    <h3>{{ $t("general.privacy-sensitive") }}</h3>
                    <p v-html="$t('general.privacy-sensitive-text')"></p>
                </div>

                <div class="privacy-element">
                    <h3>{{ $t("general.privacy-goal") }}</h3>
                    <p v-html="$t('general.privacy-goal-text')"></p>
                </div>

                <div class="privacy-element">
                    <h3>{{ $t("general.privacy-save") }}</h3>
                    <p v-html="$t('general.privacy-save-text')"></p>
                </div>

                <div class="privacy-element">
                    <h3>{{ $t("general.privacy-share") }}</h3>
                    <p v-html="$t('general.privacy-share-text')"></p>
                </div>

                <div class="privacy-element">
                    <h3>{{ $t("general.privacy-cookie") }}</h3>
                    <p v-html="$t('general.privacy-cookie-text')"></p>
                </div>

                <div class="privacy-element">
                    <h3>{{ $t("general.privacy-edit") }}</h3>
                    <p v-html="$t('general.privacy-edit-text')"></p>
                    <p>{{ $t("general.privacy-edit-update") }}: 10/05/2021</p>
                </div>
            </section>
        </main>
        <Footer v-if="$route.name !== '404'" />
    </div>
</template>

<script>
import Footer from "@/components/layout/Footer";

export default {
    components: {
        Footer
    },
    data() {
        return {
            company: process.env.VUE_APP_COMPANY_NAME,
            vat: process.env.VUE_APP_COMPANY_VAT,
            emailDecoded: atob(process.env.VUE_APP_EMAIL_BASE64),
            emailPrefix: atob("bWFpbHRvOg==")
        };
    },
    metaInfo() {
        return {
            title: "Privacy",
            htmlAttrs: {
                lang: this.$root.$i18n.locale
            },
            meta: [
                // Primary Meta Tags
                {
                    name: "title",
                    content: "Privacy - Renders by Julie"
                },
                {
                    name: "description",
                    content: "Privacybeleid van de website van Renders by Julie"
                },
                // Secondary Meta Tags
                {
                    name: "author",
                    content: "Julie Lejour"
                },
                {
                    name: "robots",
                    content: "noindex, nofollow"
                },
                {
                    "http-equiv": "Content-Type",
                    content: "text/html; charset=utf-8"
                },
                {
                    name: "language",
                    content: this.$root.$i18n.locale === "en" ? "EN" : "NL"
                },
                {
                    name: "publisher",
                    content: "Renders by Julie"
                },
                {
                    name: "copyright",
                    content: `Copyright ${new Date().getFullYear()} Renders by Julie - All Rights Reserved`
                }
            ]
        };
    }
};
</script>

<style lang="scss">
.privacy {
    main {
        min-height: 100vh;
    }

    .header {
        margin-bottom: 3.5rem;

        .title {
            text-align: right;
            font-family: "Nelphim", serif;
            font-weight: normal;
        }

        @media (max-width: 700px) {
            padding-top: 0;

            .title {
                text-align: left;
            }
        }
    }

    section:not(:last-of-type) {
        margin-bottom: 5rem;
    }

    .privacy-inner {
        padding: 0;
        margin: 0;
    }

    h3 {
        font-size: 36px;
        margin: 0;
        font-family: "Nelphim", serif;
        font-weight: normal;
    }

    p {
        font-size: 18px;
        font-family: "Titillium Web", sans-serif;
    }

    a {
        color: $red;
        transition: all 0.1s linear;

        &:hover {
            color: $maroon;
            text-decoration: underline;
        }
    }

    ul {
        list-style-type: disc;
        padding: 1rem 2rem;

        li {
            line-height: 2rem;
        }
    }

    .privacy-element {
        margin: 3rem 0;
    }
}
</style>
